<template>
  <div v-if="config.formLayout" class="quote-normal h-font-lg">
    <div class="quote-normal_content">
      <HYForm
          ref="ownerFormRef"
          fmCode="owner"
          :config="config.formLayout['owner']"
          :form="pageForm['owner']">
      </HYForm>
    </div>
    <div class="quote-normal_bnt">
      <div class="quote-normal_bnt--fixed" >
        <van-button type="primary" block round @click="back('owner')">确认</van-button>
      </div>
    </div>
</div>
</template>
<script>
import HYForm from "@/components/Form.vue";
import layout from "../layout/index";
import mixinBase from '../common/mixin/base';
import mixinCommon from '../common/mixin/common';
import { mapState } from "vuex";
import { Notify } from "vant";
export default {
  name: "ownerModel",
  components: { HYForm},
  mixins: [mixinBase,mixinCommon],
  provide() {
    return {
      pageContext: this,
      pageForm:this.pageForm,
      pageLayout:this.pageLayout
    };
  },
  data() {
    return {
      pageData: {
      },
      config: {
        scriptConfig: null,
        formLayout: null,
      },
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    ...mapState("car", ["quote"]),
  },
  async created() {
    await this.initData();
    this.initLayoutBase();
  },
  mounted() {
  },
  methods: {
    async initData() {
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    },
    initLayoutBase() {
      const layoutData = layout("BASE");
      if (layoutData == null) {
        return null;
      }
      this.config.scriptConfig = layoutData.scriptConfig;
      const layoutAddData = layoutData.addConfig(this,this.pageForm,this.pageLayout);
      this.initPageData(layoutAddData)
      Object.assign(this.pageForm,this.quote)
      this.config.formLayout = layoutAddData;
      return layoutData;
    },
    async back(refCode) {
      const formRef=this.$refs[refCode+'FormRef']&&this.$refs[refCode+'FormRef']
      const validate = await formRef.validateForm();
      if (!validate) {
        Notify({ type: "warning", message: "请完善信息" });
        return false
      }
      this.pageForm.vehicle.vehicleUsageDetail = this.pageForm.owner.custType === '1' ? '03' : '01'
      await this.$store.dispatch("car/setQuote", this.pageForm);//保存缓存
      this.$router.go(-1);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/mixins/hairline.scss";
.quote-normal{
  background-color: $background-color;
  height: 100vh;
  &_bnt{
    height: 64px;
    &--fixed{
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 99;
      background-color: $white;
      padding: 10px;
      border-top: $background-color solid $border-radius-sm;
    }
  }
}
.quote-normal ::v-deep{
  .van-tabs__content{
    margin-top:10px;
  }
  .van-field__right-icon{
    color: $primary-color;
  }
  .form-header{
    background-color: $white;
    padding: 15px;
    position: relative;
    &::after {
      @include hairline-bottom($border-color, $padding-sm, $padding-sm);
    }
  }
}
</style>
